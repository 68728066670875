require(['jquery', 'table-dropdown-responsive'], function ($) {
  if ($('table').length > 0) {
    $('table').tableDropdownResponsive();
  }
  handleDialogConcoursReer();
  function handleDialogConcoursReer() {
    var dialogSelector = '.dialog-concours-reer';
    var btnOuvrirReerSelector = '.btn-ouvrir-reer';
    if ($(dialogSelector).length === 0) {
      return;
    }
    var dialogElement = $(dialogSelector).detach();
    $('body').append(dialogElement);
    var $btnOuvrirReerSelector = $(btnOuvrirReerSelector);
    $btnOuvrirReerSelector.on('click', function () {
      var dialog = document.querySelector(dialogSelector);
      if (dialog) {
        dialog.showModal();
      }
    });
    var dialog = document.querySelector(dialogSelector);
    if (dialog) {
      dialog.addEventListener('click', function (event) {
        var rect = dialog.getBoundingClientRect();
        if (event.clientX < rect.left || event.clientX >= rect.right || event.clientY < rect.top || event.clientY >= rect.bottom) {
          dialog.close();
        }
      });
    }
    function getCookie(name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(';').shift();
      return null;
    }
    var cookielienPerso = getCookie('advisor-referrer-name');
    if (cookielienPerso) {
      var heroCtaContactAdvisor = document.querySelector('.grille-zone-cta a[id^="bta-lien-epargne-trouver-conseiller"]');
      if (heroCtaContactAdvisor) {
        $btnOuvrirReerSelector.attr('href', heroCtaContactAdvisor.href);
        $('.js-deja-client').hide();
        $('.js-veux-devenir-client').hide();
        $('.js-text-info').removeClass('margin-bottom-0').addClass('margin-bottom-24');
      }
    }
  }
  var contributeButtons = document.querySelectorAll('.contribute-popup');
  var popupButtonReerCeli = document.querySelector('.grille-bloc-questions .bloc-bta-wrapper a');
  var popupButtonReerCeliAvecConcours = document.querySelector('.grille-bloc-questions-avec-concours .bloc-bta-wrapper a');
  var popupButtonEpargne = document.querySelector('.grille-zone-importance-epargne .bloc-bta-wrapper a');
  var popupButtonZoneConcoursReerCeli = document.querySelector('.grille-zone-concours-reer .bloc-bta-wrapper a');
  var popupButtonCampagneEpargne = document.querySelector('.grille-bloc-questions-avec-concours .bloc-bta-wrapper a');
  var popupButtonCampagneEpargneCalculateur = document.querySelector('.base-calculator .bloc-contenu-vignette-concours-reer .bloc-bta-wrapper a');
  if (contributeButtons && contributeButtons.length > 0) {
    if (popupButtonReerCeli) {
      contributeButtons.forEach(function (button) {
        button.addEventListener('click', function () {
          popupButtonReerCeli.click();
        });
      });
    }
    if (popupButtonEpargne) {
      contributeButtons.forEach(function (button) {
        button.addEventListener('click', function () {
          popupButtonEpargne.click();
        });
      });
    }
    if (popupButtonZoneConcoursReerCeli) {
      contributeButtons.forEach(function (button) {
        button.addEventListener('click', function () {
          popupButtonZoneConcoursReerCeli.click();
        });
      });
    }
    if (popupButtonReerCeliAvecConcours || popupButtonCampagneEpargne || popupButtonCampagneEpargneCalculateur) {
      contributeButtons.forEach(function (button) {
        button.addEventListener('click', function () {
          popupButtonReerCeliAvecConcours.click();
        });
      });
    }
  }
});